import React from 'react';
import { connect } from 'react-redux';
import {get_stote} from '../redux/actions.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Error_data from '../error_data'
import {store_select_count,card_put} from '../redux/actions.js'
import Add_to_cart from './add_to_cart'

import './item.css';

function StoreContainer(props) {

	var Store;

	if(props.state.isFetching || props.personal.isFetching)
		return (<CircularProgress/>)
	else if (props.state.isError)
		return (<Error_data repeate={(event)=>props.get_stote(props.apikey,[{item:props.item.good_uuid,variant:props.item.variant_uuid}],props.personal.sel_agreement,props.item.catalog_uuid)}/>)
	else if(!props.state.isReceived && props.guid !== undefined)
	{
		props.get_stote(props.apikey,[{item:props.guid,variant:props.variant_uuid}],props.personal.sel_agreement,props.item.catalog_uuid);
	}
	else if (props.state.isReceived) {
		Store = props.state.items.reduce((combine,prev)=>{if(prev.good_uuid === props.guid)return prev; else return combine},undefined);
		if(!Store)
			props.get_stote(props.apikey[{item:props.guid,variant:props.variant_uuid}],props.personal.sel_agreement,props.item.catalog_uuid);
		Store.art = props.item.art;
		Store.file = props.item.file;
		Store.file_ext = props.item.file_ext;
		Store.file_uuid = props.item.file_uuid;
	}

	var card = props.card.items.reduce((combine,prev)=>{if(prev.good_uuid === props.guid && prev.variant_uuid === props.variant_uuid)return prev; else return combine},{});

	if(!Store)
	{
		return (<CircularProgress/>);
	}

	return (
			<div className="store">
				<div className="price">{Store.price} ₽</div>
				{/*<div className="quantity">Доступно: {Store.quantity > 0 ? "В наличии" : 0}</div>*/}
				{/*<div className="pack">Упаковка: {Store.pack} ({Store.koef} {Store.unit})</div>*/}
				{Store.variant && (<div className="pack">Вариант: {Store.variant}</div>)}
				<div className="market_add">
					<Add_to_cart count={card.quantity} min_count={Store.koef} quantity={Store.quantity} item={Store} price={Store.price} put={props.card_put}/>
				</div>
			</div>
	);

}

const mapStateToProps = function(state) {
	return {
	  	'state':state.store,
	  	'card':state.card,
	  	'item':state.item,
	  	'catalogs':state.metadata.catalogs,
	  	'apikey':state.auth.key,
	  	'personal':state.personal
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
		'get_stote': (key,item,agreement,catalog) => {
			dispatch(get_stote(key,item,agreement,catalog));
		},
		'store_select_count': (value) => {
			dispatch(store_select_count(value));
		},
		'card_put': (item) => {
			console.log(item);
			dispatch(card_put(item));
		},
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(StoreContainer);

import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {
    card_put,
    change_list_option,
    flush_items,
    get_catalog_items,
    get_more_catalog_items, items_select_count, select_item, set_filter,
    set_personal_data
} from "../../redux/actions";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        //padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 220,
        border: "1px #ddd solid",
        background: "#f8f8f8",
        borderRadius: "0px",
        boxShadow: "none",
        height: "28px",
        position: "absolute",
        right: "10px",
        top: "0px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: "14px",
        lineHeight: 1.55,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function SearchInput({filter,set_filter}) {
    const classes = useStyles();

    const [_search,_set_search] = useState("");

    const handleOnChange = (event) => {
        _set_search(event.target.value);
    }

    const onClear = (event) =>{
        _set_search("");
        set_filter("");
        event.preventDefault();
    }

    const onSubmit = (event) =>{
        set_filter(_search);
        event.preventDefault();
    }

    useEffect(() => {
        _set_search(filter.search);
    },[filter.search]);

    return (
        <Paper component="form" className={`${classes.root} search`} onSubmit={onSubmit}>
            <InputBase
                className={classes.input}
                placeholder="Поиск"
                value={_search}
                onChange={handleOnChange}
            />
            <IconButton onClick={onClear} className={classes.iconButton} aria-label="search">
                <ClearIcon/>
            </IconButton>
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon/>
            </IconButton>
        </Paper>
    );
}

const mapStateToProps = function(state) {
    return {
        'filter':state.filter,
    }
}

const mapDispatchToProps = function(dispatch,state) {
    return {
        'set_filter': (text) => {

            dispatch(set_filter({search:text}));

        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchInput);

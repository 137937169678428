import {
	REQUEST_CATALOG,
	ERROR_CATALOG,
	RECEIVE_CATALOG,
	SEL_CATEGORY,
	FLUSH_CATALOG,
	AUTH_SUCSESS,
	AUTH_LOGOUT
} from '../types'

import {get_path,get_category_tree} from '../actions.js'

let initial_value = {
	'sel_category':[]
}

let initial_catalog = {
	'isFetching':false,
	'isReceived':false,
	'isError':false,
	'crumps':[],
	'category':[],
	'path':[]
}

const catalogReducer = (state = initial_value,action) => {

	let newState;

	switch (action.type) {
		case AUTH_LOGOUT:
			newState = Object.assign({}, initial_value);
			return newState;
		case FLUSH_CATALOG:
			newState = Object.assign({}, initial_value);
			return newState;
		case REQUEST_CATALOG:
			newState = Object.assign({}, state);
			newState[action.uuid] = Object.assign(initial_catalog,{isFetching:true});
			return newState;
		case ERROR_CATALOG:
			newState = Object.assign({}, state);
			newState[action.uuid] = Object.assign({}, newState[action.uuid],{isFetching:false,'isError':true});
			return newState;
		case SEL_CATEGORY:
			newState = Object.assign({}, state);
			newState.sel_category = get_category_tree(action.item);
			return newState;
		case RECEIVE_CATALOG:

			newState = Object.assign({}, state);

			newState[action.uuid] = Object.assign({},newState[action.uuid],{isReceived:true,isFetching:false,isError:false});

			newState[action.uuid].path = {};

			if(action.data.catalog)
			{

				newState[action.uuid].category = action.data.catalog.category;
				newState[action.uuid].filters = action.data.catalog.filters;
				newState[action.uuid].crumps   = [];
				newState[action.uuid].crumps.push({name:action.data.catalog.catalog,path:action.data.catalog.path+"/",item:action.data.catalog});

				if(action.data.catalog.category)
					Object.assign(newState[action.uuid].path,get_path(action.data.catalog.category,action.data.catalog.path+"/",undefined,newState[action.uuid].crumps));
			}

			return newState;
		default:
			return state;
	}
}

export default catalogReducer;

import React from 'react';
import {NavLink} from 'react-router-dom';
import Lazy_image from './lazy_image'

function Element(props) {
	
	const Children = (props) => {
		return (
			<ul className="sub_category">
				{
					props.items.map((item,index) => {return (<li key={index}><NavLink to={"/catalog/"+item.path}>{item.category}</NavLink></li>)})
				}
			</ul>			
		)
	}
	
	return (
		<li>
			<NavLink to={"/catalog/"+props.item.path}>
				{props.item.category}
			</NavLink>
			{(props.item.file!=="")?(<NavLink to={"/catalog/"+props.item.path}><Lazy_image className={props.item.children.length===0?"center":"left"} placeholder="/images/photo-loader.gif" src={"/photo/catalog/s2/"+props.item.file_uuid+"."+props.item.file_ext} /></NavLink>):''}
			{/* {props.item.children.length>0?(<Children items={props.item.children} />):''} */}
		</li>)
}

export default Element;

export function Children_list(props) {
	
	return (
		<ul className="sub_category_menu">
			{
				props.items.map((item,index) => {return (<li key={props.category_uuid}><NavLink to={"/catalog/"+item.path}>{item.category}</NavLink></li>)})
			}
		</ul>			
	)	
	
}

export function Element_list(props) {
	
	return (
		<li>
			<NavLink to={"/catalog/"+props.item.path}>
				{props.item.category}
			</NavLink>
			{props.item.children.length>0?(<Children_list items={props.item.children} />):''}
		</li>)
}
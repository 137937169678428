import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import Error_data from '../error_data'
import Lazy_image from './lazy_image';

import {isMobile} from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PaginationUI from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import Add_to_cart from './add_to_cart'

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';

import {round} from '../func.js'

import {get_catalog_items,set_personal_data,flush_items,items_select_count,getAllUrlParams,change_list_option,get_more_catalog_items,card_put,select_item} from '../redux/actions.js'


import './items_list.css';
import SearchInput from "./components/search";

function Table(props) {
	return (
		<table>
			<thead>
				<tr>
					<th className="photo">Фото</th>
					<th className="code">Код</th>
					<th className="name">Наименование</th>
					<th className="count">На складе</th>
					<th className="pack">Упаковка</th>
					<th className="price">Цена</th>
					<th className="market_add">В корзине</th>
				</tr>
			</thead>
			<tbody>
				{
					props.items.map(

						(item,index) => {

							let in_card = props.card.items.filter((citem)=>item.good_uuid === citem.good_uuid && item.variant_uuid === citem.variant_uuid);

							return (
								<tr key={index}>
									<td>{item.file && (<Lazy_image placeholder="/images/photo-loader.gif" src={"/photo/good/s1/"+item.file_uuid+"."+item.file_ext} />)}</td>
									<td>{item.code}</td>
									<td className={"name"}><a href={`/item/${props.category.path}/${item.good_uuid}${item.variant_uuid?"_"+item.variant_uuid:""}`} onClick={(event)=>{props.select_item(item.good_uuid,item.variant_uuid);event.preventDefault()}}>{item.art}</a></td>
									<td className="count">{item.quantity > 0 ? "В наличии" : 0}</td>
									<td className="pack">{item.pack}</td>
									<td className="price">{round(item.price)} ₽</td>
									<td className="market_add">
										<Add_to_cart count={in_card.length>0?in_card[0].quantity:0} min_count={item.koef} quantity={item.quantity} item={item} put={props.card_put} price={item.price} />
									</td>
								</tr>
							)
						})
				}
			</tbody>
		</table>
	)
}

function List(props) {
	const filters =props?.filter?.filters;
	const style = {
		filter: 'blur(3px)',
		opacity: '0.3'
	};

	return (
			<div className="items_list_wrapper" style={props.isFetching ? style : null}>
				{
				props.items.map(

					(item,index) => {


						if(props?.filter?.search && item.art.toUpperCase().indexOf(props?.filter?.search.toUpperCase()) === -1)
							return;

						if(filters?.length) {
							const res = filters.reduce((acc,el)=>{
								if(acc) return true;
								return item[el.id+"_uuid"] === el.value;
							},false);
							if(!res)
								return;
						}

						//item.file_uuid = "56a8b3d3-77e1-11ee-80d8-fa07df0f7ab7";
						//item.file_ext = "jpg";
						//item.file = 1;

						let in_card = props.card.items.filter((citem)=>item.good_uuid === citem.good_uuid && item.variant_uuid === citem.variant_uuid);

						return (
							<div className="item" key={`good_${item.good_uuid}`}>
								<div className="wrapper">
									<div className="image">
										{item.file && (<Lazy_image placeholder="/images/photo-loader.gif" src={"/photo/good/l1/"+item.file_uuid+"."+item.file_ext} />)}
									</div>
									<div className="info">
										<div className="name">
											{item.art}
											{/*<a href={"/item/"+props.category.path+"/"+item.good_uuid} onClick={(event)=>{props.select_item(item.good_uuid,item.variant_uuid);event.preventDefault()}} >{item.art}</a>*/}
										</div>
										<div className="vol">
											{item.vol}
										</div>
										<div className="about">{item.about}</div>
										<div className="price">{round(item.price)} ₽</div>
										<div className="market_add">
											<Add_to_cart count={in_card.length>0?in_card[0].quantity:0} min_count={item.koef} quantity={item.quantity} item={item} put={props.card_put} price={item.price} />
										</div>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
	)
}

function Items_listContainer(props) {

	// let search = getAllUrlParams(props.search);
	// if(!search.page)
	// 	search.page = 1;

	useEffect(
		()=>{
			props.get_catalog_items(props.apikey,props.category.category_uuid,props.state.page,{list_size:props.personal.list_size,list_order:props.personal.list_order,sel_agreement:props.personal.sel_agreement});
		}
		,[props.category]
	);

	// useEffect(
	// 	()=>{
	// 		if(props.state.isError) {
	// 			props.get_catalog_items(props.apikey,props.category.category_uuid,props.state.page,{list_size:props.personal.list_size,list_order:props.personal.list_order,sel_agreement:props.personal.sel_agreement});
	// 		}
	// 	}
	// 	,[props.category.category_uuid]
	// );

	if(props.state.isError)
		return (<Error_data repeate={() => props.get_catalog_items(props.apikey,props.category.category_uuid,props.state.page,{list_size:props.personal.list_size,list_order:props.personal.list_order,sel_agreement:props.personal.sel_agreement})} />)

	// else if(props.state.isFetching)
	// 	console.log("fetching...");
	// 	//return (<CircularProgress/>);
	// else if(!props.state.isFetchingMore && (parseInt(search.page)-1)!==props.state.page)
	// {
	// 	props.change_list_option_reload({'page':parseInt(search.page)-1});
	// }
	// else if(!props.state.isReceived || !props.category || props.category.category_uuid !== props.state.uuid)
	// {
	// 	setTimeout(()=>props.get_catalog_items(props.apikey,props.category.category_uuid,props.state.page,{list_size:props.personal.list_size,list_order:props.personal.list_order,sel_agreement:props.personal.sel_agreement}));
	// }

	// const SortType = [
	// 	{id:'ABC',name:'По алфавиту'},
	// 	{id:'PRICE_UP',name:'По цене (дешевле - дороже)'},
	// 	{id:'PRICE_DOWN',name:'По цене (дороже - дешевле)'},
	// 	{id:'BALANCE',name:'По остатку'}];

	//const ListSize = [10,50,100,200];

	// const More = () => {
	// 	if(props.state.isFetchingMore)
	// 		return (<CircularProgress/>);
	//     else if(props.state.total_pages > Math.max(props.state.more_page,props.state.page)+1)
	//     	return (<div className="more"><Button onClick={() => {props.get_more_catalog_items(props.apikey,props.category.category_uuid,Math.max(props.state.more_page,props.state.page),{list_size:props.personal.list_size,list_order:props.personal.list_order,sel_agreement:props.personal.sel_agreement})}}>Загрузить еще</Button></div>);
	//     else
	// 		return null;
	// };

	// let list_style_css = {
	// 	list:{
	// 		color:"#000",
	// 		width:"30px",
	// 		height:"30px",
	// 	},
	// 	table:{
	// 		color:"#000",
	// 		width:"30px",
	// 		height:"30px",
	// 	}
	// }

	// if(props.personal.list_style==="table")
	// 	list_style_css.table.color = "rgb(205 205 205)";
	//
	// if(props.personal.list_style==="list")
	// 	list_style_css.list.color = "rgb(205 205 205)";

	const ListOptions = () => {

		return (
			<div className={"ListOptions"}>
				<SearchInput />
			</div>
		)
	}

	const select_item = (good_uuid,variant_uuid) => {
		props.select_item(props.apikey,good_uuid,variant_uuid,props.metadata.catalogs.reduce((inst,prev) => {if(prev.path===props.catalog) return prev.catalog_uuid},""));
	}

	return (
		<div className="items_list">
			<ListOptions />
			<List isFetching={props.state.isFetching} catalog={props.catalog} category={props.category} card={props.card} select_item={select_item} items={props.state.items} items_select_count={props.items_select_count} card_put={props.card_put} filter={props.filter} />
		</div>
	);
}


const mapStateToProps = function(state) {
	return {
	  	'metadata':state.metadata,
	  	'state':state.items,
	  	'personal':state.personal,
	  	'apikey':state.auth.key,
	  	'item':state.item,
	  	'card':state.card,
		'filter':state.filter
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
	  	'get_catalog_items': (key,uuid,page,personal) => {

		  	dispatch(get_catalog_items(key,uuid,page,personal));

		},
		'get_more_catalog_items': (key,uuid,page,personal) => {
		  	dispatch(get_more_catalog_items(key,uuid,page+1,personal));

		},
	  	'set_personal_data': (key,value) => {
		  	dispatch(set_personal_data(key,value));
		  	dispatch(flush_items());
		},
		'change_list_option': (value) => {
		  	dispatch(change_list_option(value));
		},
		'change_list_option_reload': (value) => {
		  	dispatch(flush_items());
		  	dispatch(change_list_option(value));
		},
		'items_select_count': (index,value) => {
			dispatch(items_select_count(index,value));
		},
		'card_put': (item) => {
			dispatch(card_put(item));
		},
		'select_item': (key,item,variant,catalog) => {
			dispatch(select_item(key,item,variant,catalog));
		},
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(Items_listContainer);

export function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export function round(x, format = true) {
    let val = Math.round(x * 100) / 100;
    if (format)
        val = number_format(val, 0, ',', ' ');
    return val;
}

export function location_info(path) {

    var result = {};

    if (path.substring(0, 6) === "/item/") {

        let regex = /\/item\/([^\/]+)/g;
        let catalog = regex.exec(path);
        if (catalog.length > 1)
            result.catalog = catalog[1];

        let find_uuid = path.match(/[^\/]+$/g);
        if (find_uuid && find_uuid.length > 0) {
            let uuid = find_uuid[0].split('_');
            result.item = uuid[0];
            result.variant = uuid[1];
        }
    }
    return result;

}

export function baseGet(object, path,base_return=undefined) {
    path = path.split('.');

    var index = 0,
        length = path.length;

    while (object != null && index < length) {
        object = object[path[index++]];
    }
    return (index && index === length) ? (object!==undefined)?object:base_return : base_return;
}

export function exportFields(obj,fields_str) {
    const newObj = {};
    const fields = fields_str.split(',');
    for (const field of fields) {
        newObj[field] = obj[field];
    }
    return newObj;
};

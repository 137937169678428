export const AUTH_LOGIN    = 'AUTH_LOGIN'
export const AUTH_ON_LOGIN = 'AUTH_ON_LOGIN'
export const AUTH_SUCSESS  = 'AUTH_SUCSESS'
export const AUTH_ERROR    = 'AUTH_ERROR'
export const AUTH_LOGOUT   = 'AUTH_LOGOUT'

export const REQUEST_METADATA    = 'REQUEST_METADATA';
export const ERROR_METADATA      = 'ERROR_METADATA';
export const RECEIVE_METADATA    = 'RECEIVE_METADATA';
export const METADATA_SET_CRUMPS = 'METADATA_SET_CRUMPS';

export const REQUEST_CATALOG  = 'REQUEST_CATALOG';
export const ERROR_CATALOG    = 'ERROR_CATALOG';
export const RECEIVE_CATALOG  = 'RECEIVE_CATALOG';
export const SEL_CATEGORY     = 'SEL_CATEGORY';
export const FLUSH_CATALOG    = 'FLUSH_CATALOG';

export const REQUEST_PERSONAL_DATA    = 'REQUEST_PERSONAL_DATA';
export const ERROR_PERSONAL_DATA      = 'ERROR_PERSONAL_DATA';
export const RECEIVE_PERSONAL_DATA    = 'RECEIVE_PERSONAL_DATA';
export const SET_PERSONAL_DATA        = 'SET_PERSONAL_DATA';
export const SET_PERSONAL_OPTION	  = 'SET_PERSONAL_OPTION';

export const REQUEST_ITEMS_DATA      = 'REQUEST_ITEMS_DATA';
export const REQUEST_MORE_ITEMS_DATA = 'REQUEST_MORE_ITEMS_DATA';
export const ERROR_ITEMS_DATA        = 'ERROR_ITEMS_DATA';
export const RECEIVE_ITEMS_DATA      = 'RECEIVE_ITEMS_DATA';
export const RECEIVE_MORE_ITEMS_DATA = 'RECEIVE_MORE_ITEMS_DATA';
export const FLUSH_ITEMS_DATA        = 'FLUSH_ITEMS_DATA';
export const SET_ITEM_COUNT          = 'SET_ITEM_COUNT';
export const SET_LIST_OPTION         = 'SET_LIST_OPTION';

export const REQUEST_CARD			 = 'REQUEST_CARD';
export const ERROR_CARD				 = 'ERROR_CARD';
export const RECEIVE_CARD			 = 'RECEIVE_CARD';
export const CARD_PUT				 = 'CARD_PUT';
export const CARD_DEL				 = 'CARD_DEL';
export const SYNC_CARD				 = 'SYNC_CARD';
export const FLUSH_CARD				 = 'FLUSH_CARD';

export const REQUEST_ITEM			 = 'REQUEST_ITEM';
export const ERROR_ITEM				 = 'ERROR_ITEM';
export const RECEIVE_ITEM			 = 'RECEIVE_ITEM';
export const FLUSH_ITEM				 = 'FLUSH_ITEM';
export const SET_ITEM_PHOTO			 = 'SET_ITEM_PHOTO';

export const REQUEST_STORE			 = 'REQUEST_STORE';
export const ERROR_STORE		 	 = 'ERROR_STORE';
export const RECEIVE_STORE			 = 'RECEIVE_STORE';
export const FLUSH_STORE			 = 'FLUSH_STORE';
export const SET_STORE_COUNT		 = 'SET_STORE_COUNT';

export const REQUEST_ORDER			 = 'REQUEST_ORDER';
export const ERROR_ORDER 			 = 'ERROR_ORDER';
export const RECEIVE_ORDER 			 = 'RECEIVE_ORDER';
export const SYNC_ORDER 			 = 'SYNC_ORDER';
export const ORDER_UPDATE			 = 'ORDER_UPDATE';
export const FLUSH_ORDER			 = 'FLUSH_ORDER';

export const SET_FILTER			     = 'SET_FILTER';
export const ADD_FILTER			     = 'ADD_FILTER';
export const DEL_FILTER              = 'DEL_FILTER';

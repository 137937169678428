import React from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green} from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import Person from '@material-ui/icons/Person';
import {auth_login} from './redux/actions.js'
import Button from "@material-ui/core/Button";

function LoginContainer(props) {

    const [login, setLogin] = React.useState({'username': "", "password": ""});
    const [validation,setValidation] = React.useState({});
    React.useEffect(() => {
    }, []);

    const handleChange = (name) => event => {
        let NewLogin = {...login};
        NewLogin[name] = event.target.value;
        setLogin(NewLogin);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let validation = {};
        if(login.username==='')
            validation.username = "Не заполно имя пользователя";
        if(login.password==='')
            validation.password = "Не заполн пароль";
        if(Object.keys(validation).length)
            setValidation(validation);
        else
            props.sumbit(login.username, login.password);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={"login"}>
                <div className={"wrapper"}>
                    <h1>Авторизация</h1>
                    <div>
                        <TextField
                            label="Имя пользователя"
                            margin="normal"
                            value={login.username}
                            onChange={handleChange('username')}
                            disabled={props.state.isFetching}
                            variant="outlined"
                            error={validation?.username ? true : false}
                            helperText={validation?.username}
                        />
                    </div>
                    <div>
                        <TextField
                            type="password"
                            label="Пароль"
                            margin="normal"
                            value={login.password}
                            onChange={handleChange('password')}
                            disabled={props.state.isFetching}
                            variant="outlined"
                            error={validation?.password ? true : false}
                            helperText={validation?.password}
                        />
                    </div>
                    <div>
                        <div>


                            <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={props.state.isFetching}
                            >
                                {
                                    props.state.isFetching ? <CircularProgress size={28}/> : "Войти"
                                }
                            </Button>

                            {props.state.isFetching && <CircularProgress size={68} />}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = function (state) {
    return {
        'state': state.auth
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        'sumbit': (username, password) => {
            dispatch(auth_login(username, password));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

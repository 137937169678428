let initial_alert = {'errors': []}

const alertReducer = (state = initial_alert, action) => {

    switch (action.type) {
        case "ALERT_ERROR_ADD" :

            let newErrors = [...state.errors];
            newErrors.push(action.text)

            return Object.assign({}, state, {'errors': newErrors});

        case "ALERT_ERROR_CLEAN" :

            return Object.assign({}, state, {'errors': []});

        default:
            return state;
    }
}

export default alertReducer;
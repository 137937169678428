import React  from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import {flush_item} from '../redux/actions.js'

import Item from './item'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogContainer(props) {

	return (
		<Dialog fullScreen open={true} TransitionComponent={Transition} className="item_box">
			<div className="close">
				<IconButton edge="start" color="inherit" onClick={() => props.flush_item()} aria-label="close">
					<CloseIcon />
				</IconButton>
			</div>
			<Item catalog={props.catalog} store={props.store} />
		</Dialog>
	);
}

const mapStateToProps = function(state) {
	return {
	  	'state':state.item,
	  	'apikey':state.auth.key
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
		'flush_item': () => {
		  	dispatch(flush_item());
		},
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(DialogContainer);

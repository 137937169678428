import React, {useState} from "react";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Lazy_image from "../catalog/lazy_image";
import Add_to_cart from "../catalog/add_to_cart";
import {round} from "../func";
import {NavLink} from "react-router-dom";

function CardBlock({open,close,state,card_put,card_del})
{

    const sum = state.items.reduce((accumulator, currentValue) => {
        accumulator.sum = accumulator.sum + currentValue.quantity * currentValue.price;
        accumulator.quantity = accumulator.quantity + currentValue.quantity;
        return accumulator;
    }, {sum: 0, quantity: 0});

    return (
        <>
            <div className={`makeorder_block ${open ? 'open' : 'close'}`} onClick={close}>
            </div>
            <div className={`makeorder ${open ? 'open' : 'close'}`}>
                <div className="wrapper">
                    <h1>Ваш заказ</h1>
                    <IconButton onClick={close} className={"close"}><CloseIcon /></IconButton>
                    {
                        state?.items?.map((item)=>
                            (
                                <div className="product" key={`card_${item.good_uuid}`}>
                                    <div className="img" style={{backgroundImage: `url(/photo/good/s3/${item?.file_uuid}.${item?.file_ext})`}}></div>
                                    <div className="name">
                                        <div className="art">{item.art}</div>
                                        <div className="vol">Объём: <span>{item.pack}</span></div>
                                    </div>
                                    <div className="market_add"><Add_to_cart iscard={true} count={item.quantity} min_count={item.koef} price={item.price}
                                                      quantity={item.quantity} item={item} put={card_put}/></div>
                                    <div>{round(item.quantity * item.price)} ₽</div>
                                    <div className="del" onClick={(event) => card_del(item)}>
                                        <img src="/images/arrows_circle_remove.svg" />
                                    </div>
                                </div>
                            )
                        )
                    }
                    <div className="sum">Сумма: {round(sum.sum)} ₽</div>

                    <NavLink to="/order" onClick={close}><button className={"order"}>Оформить заявку</button></NavLink>

                </div>
            </div>
        </>
    )
}

export default CardBlock;

import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {NavLink} from "react-router-dom";
import {useQuery} from "react-query";
import {GetAPI, OrdersAPI} from "./api/rest1C";
import {LinearProgress} from "@material-ui/core";

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    backgroundSize: 'cover',
    height: '550px'
}


const Slideshow = () => {

    const { isLoading,isFetching, error, data : slides, refetch } = useQuery(
        'SlidesData',
        ()=>GetAPI({url:"content/slides?populate=*"})
    );

    if (isLoading || isFetching) return (<LinearProgress />)

    return (
        <div className="slide-container">
            <Slide>
                {slides?.data.map((slideImage, index)=> (
                    <div key={slideImage?.id}>
                        <div className="slide-slide" style={{...divStyle,'backgroundImage': `url(${slideImage?.attributes?.image?.data?.attributes?.url})` }}>
                            <div className="wrapper">
                                <div className="slide-content">
                                    <div className="caption">{slideImage?.attributes?.header}</div>
                                    <div className="description">{slideImage?.attributes?.text}</div>
                                    {slideImage?.attributes?.button_link && (<a href={slideImage?.attributes?.button_link}>
                                        {slideImage?.attributes?.button_text}
                                    </a>)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default Slideshow;

function replacer(key, value) {
    return value;
}

function reviver(key, value) {
    if (typeof value === 'string') {
        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[Z]*$/.test(value)) {
            if (value === '0001-01-01T00:00:00') {
                return null;
            }
            return new Date(value);
        } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
            if (value === '0001-01-01T00:00:00.000Z') {
                return null;
            }
            return new Date(value);
        }
    }
    return value;
}

export async function newOrderAPI(data)
{

    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    let response;

    let responseData;
    try {
        response = await fetch(`${window.apiBaseUrl}new_order`, {
            headers: headers,
            timeout: 2000,
            body: JSON.stringify(data, replacer),
            method: 'POST',
        });
        let responseTest = await response.text();
        responseData = JSON.parse(responseTest, reviver);
    } catch (e) {throw "Ошибка создания заказа"}

    if(responseData?.result === false)
        throw responseData.message;

    return responseData;

}

export async function OrdersAPI(data)
{

    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    let response;

    let responseData;
    try {
        response = await fetch(`${window.apiBaseUrl}orders`, {
            headers: headers,
            timeout: 2000,
            body: JSON.stringify(data, replacer),
            method: 'POST',
        });
        let responseTest = await response.text();
        responseData = JSON.parse(responseTest, reviver);
    } catch (e) {throw "Ошибка получения списка заказов"}

    if(responseData?.result === false)
        throw responseData.message;

    return responseData;

}

export async function GetAPI({url})
{

    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    let response;

    let responseData;
    try {
        response = await fetch(`${window.apiBaseUrl}${url}`, {
            headers: headers,
            timeout: 2000,
            method: 'GET',
        });
        let responseTest = await response.text();
        responseData = JSON.parse(responseTest, reviver);
    } catch (e) {throw "Ошибка"}

    if(responseData?.result === false)
        throw responseData.message;

    return responseData;

}

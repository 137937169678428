import React, {createContext, useState} from "react";
import {Backdrop} from "@material-ui/core";

export const BackgoundContext = createContext();

export default function BackgoundProvider({children}) {


    const [open,setOpen] = useState(false);

    const handleOpen = () =>
    {
        setOpen(true);
    }

    const handleClose = () =>
    {
        setOpen(false);
    }

    return (
        <BackgoundContext.Provider value={{open:handleOpen,close:handleClose}}>
            {children}
        </BackgoundContext.Provider>
    );
}

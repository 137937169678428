import {
    AUTH_ERROR,
    AUTH_LOGOUT,
    REQUEST_PERSONAL_DATA,
    ERROR_PERSONAL_DATA,
    RECEIVE_PERSONAL_DATA,
    SET_PERSONAL_DATA,
    SET_PERSONAL_OPTION
} from '../types'

let personal_initial = {
    isFetching: false,
    isReceived: false,
    isError: false,
    sel_agreement: undefined,
    sel_partner: undefined,
    list_size: 100,
    list_style: 'table',
    list_order: 'ABC',
    agreements: [],
    partner: {
        parner: undefined,
        parner_uuid: undefined,
        firms: []
    }
}

const personalReducer = (state = undefined, action) => {

    if (state === undefined)
        state = JSON.parse(JSON.stringify(personal_initial));

    let newState;

    switch (action.type) {
        case AUTH_ERROR:
            return Object.assign({}, JSON.parse(JSON.stringify(personal_initial)));
        case AUTH_LOGOUT:
            return Object.assign({}, JSON.parse(JSON.stringify(personal_initial)));
        case REQUEST_PERSONAL_DATA:
            newState = Object.assign({}, state, {isFetching: true, isError: false});
            return newState;
        case RECEIVE_PERSONAL_DATA:
            newState = Object.assign({}, state, action.data, {isReceived: true, isError: false, isFetching: false});
            return newState;
        case SET_PERSONAL_DATA:
            newState = Object.assign({}, state, action.value);
            return newState;
        case ERROR_PERSONAL_DATA:
            newState = Object.assign({}, state, {isFetching: false, isError: true});
            return newState;
        default:
            return state;
    }
}

export default personalReducer;

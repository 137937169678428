import {
	REQUEST_ITEMS_DATA,
	REQUEST_MORE_ITEMS_DATA,
	ERROR_ITEMS_DATA,
	RECEIVE_ITEMS_DATA,
	RECEIVE_MORE_ITEMS_DATA,
	FLUSH_ITEMS_DATA,
	SET_ITEM_COUNT,
	SET_LIST_OPTION,
	FLUSH_CATALOG, AUTH_SUCSESS, AUTH_LOGOUT
} from '../types'

let items = {
	isFetching:false,
	isFetchingMore:false,
	isReceived:false,
	isError:false,
	uuid:undefined,
	page: 0,
	more_page:0,
	page_size: 0,
	total: 0,
	items: []
}

const itemsReducer = (state = undefined,action) => {

	if(state === undefined)
		state = Object.assign({}, items);

	let newState;

	switch (action.type) {
		case REQUEST_ITEMS_DATA:
			newState = Object.assign({}, state,{isFetching:true,isError:false,isFetchingMore:false,page:action.page,more_page:0});
			return newState;
		case REQUEST_MORE_ITEMS_DATA:
			newState = Object.assign({}, state,{isFetchingMore:true,isError:false,more_page:action.page});
			return newState;
		case RECEIVE_ITEMS_DATA:
			newState = Object.assign({}, state,{isReceived:true,isError:false,isFetching:false,isFetchingMore:false},action.data);
			return newState;
		case RECEIVE_MORE_ITEMS_DATA:
			newState = Object.assign({}, state,{isReceived:true,isError:false,isFetching:false,isFetchingMore:false},{'items':state.items.concat(action.data.items)});
			return newState;
		case ERROR_ITEMS_DATA:
			newState = Object.assign({}, state,{isFetching:false,isFetchingMore:false,isError:true});
			return newState;
		case FLUSH_CATALOG:
			newState = Object.assign({}, state,{items: [],isReceived:false,isFetching:false,isFetchingMore:false,isError:false});
			return newState;
		case AUTH_LOGOUT:
			newState = Object.assign({}, state,{items: [],isReceived:false,isFetching:false,isFetchingMore:false,isError:false});
			return newState;
		case FLUSH_ITEMS_DATA:
			newState = Object.assign({}, state,{items: [],isReceived:false,isFetching:false,isFetchingMore:false,isError:false});
			return newState;
		case SET_LIST_OPTION:
			newState = Object.assign({}, state,action.value);
			return newState;
		case SET_ITEM_COUNT:
			newState = Object.assign({}, state);
			newState.items[action.index].sel_count = Math.max(0,Math.min(newState.items[action.index].quantity,parseInt(action.value)));
			return newState;
		default:
			return state;
	}
}

export default itemsReducer;

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {card_del, get_card, sync_card, select_item, card_put} from '../redux/actions.js'

import {round} from '../func.js'
import CardBlock from "./block";

function CardContainer(props) {

    const [isOpen,setOpen] = useState(false);

    const handleOpen = () => {
        document.body.style.overflow = "hidden";
        setOpen(true);
    }

    const handleClose = () => {
        document.body.style.overflow = "auto";
        setOpen(false);
    }

    useEffect(() => {

        if (!props.state.isError && !props.state.isReceived && !props.state.isFetching)
            props.get_card(props.apikey);

        if (!props.state.isError && !props.state.isFetching && props.state.sync === false)
            props.sync_card(props.apikey, props.state.items);
    },[props.state.sync]);

    let sum = props.state.items.reduce((accumulator, currentValue) => accumulator + round(currentValue.quantity * currentValue.price, false), 0);

    return (
        <>
            <div className="card_wrapper">
                <div className="text">
                    <div className="sum">
                        = {sum} р.
                    </div>
                </div>
                <div className="carticon-wrapper" onClick={handleOpen}>
                    <div className="imgwrap">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 64 64">
                            <path fill="none" strokeWidth="2" strokeMiterlimit="10" d="M44 18h10v45H10V18h10z"></path>
                            <path fill="none" strokeWidth="2" strokeMiterlimit="10"
                                  d="M22 24V11c0-5.523 4.477-10 10-10s10 4.477 10 10v13"></path>
                        </svg>
                    </div>
                    <div className="counter">{props.state?.items?.length}</div>
                </div>
            </div>
            <CardBlock card_put={props.card_put} card_del={props.card_del} state={props.state} open={isOpen} close={handleClose} />
        </>
    )

    // return (
    //     <div className="card_wrapper">
    //         <div className="card">
    //             {sum > 0 && (<span className="sum">{round(sum)} ₽</span>)}
    //             <NavLink to={"/card/"}>
    //                 <IconButton color="inherit">
    //                     {
    //                         (props.state.isFetching) ? (<HourglassEmpty style={{color: '#fff'}}/>) : (
    //                             <ShoppingCart style={{color: '#fff'}}/>)
    //                     }
    //                 </IconButton>
    //             </NavLink>
    //             {(props.state.items && /\/card/.test(props.browserHistory.location.pathname) === false) ? (
    //                 <div className={"items hidden"} ref={cardRef}>
    //                     <table>
    //                         <tbody>
    //                         {props.state.items.map((item, index) => {
    //                             return (<tr key={`cardpos_${index}`}>
    //                                 <td className="good"><a href={`/item/${item.catalog_path}/${item.good_uuid}${item.variant_uuid?"_"+item.variant_uuid:""}`}
    //                                                         onClick={(event) => {
    //                                                             props.select_item(props.apikey, item.good_uuid,item.variant_uuid,item.catalog_uuid);
    //                                                             event.preventDefault()
    //                                                         }}>{item.good}{item.variant?` (${item.variant})`:""}</a></td>
    //                                 <td className="unit">{item.quantity}{item.unit}</td>
    //                                 <td className="sum">{round(item.quantity * item.price)} ₽</td>
    //                                 <td><IconButton onClick={(event) => props.card_del(item)}
    //                                                 color="inherit"><DeleteForever style={{color: '#000'}}
    //                                                                                size="small"/></IconButton></td>
    //                             </tr>)
    //                         })}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             ) : null}
    //         </div>
    //     </div>
    // );
}

const mapStateToProps = function (state) {
    return {
        'state': state.card,
        'apikey': state.auth.key
    }
}

const mapDispatchToProps = function (dispatch, state) {
    return {
        'card_del': (item) => {
            dispatch(card_del(item));
        },
        'get_card': (key) => {
            dispatch(get_card(key));

        },
        'sync_card': (key, items) => {
            dispatch(sync_card(key, items));
        },
        'card_put': (item) => {
            dispatch(card_put(item));
        },
        'select_item': (key,item,variant,catalog) => {
            dispatch(select_item(key,item,variant,catalog));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);

import React from 'react';
import {connect} from 'react-redux';
import {Router, Route} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {get_metadata, get_personal_data, set_personal_data, update_catalog_data} from './redux/actions.js'
import Error_data from "./error_data"
import Dialog from './catalog/dialog';
import Catalog from './catalog/catalog';
import Catalog_menu from './catalog/catalog_menu_items.js';
import Item from './catalog/item';
import MakeOrder from './makeorder';
import Card from './card/card'

import Slideshow from "./slideshow";
import ScrollMessages from "./scrollmessages";
import Userbar from "./user";
import Canvas from "./canvas";
import Orders from "./orders";
import ScrollToTop from "./scroll";

function PersonalContainer(props) {

    // Metadata
    if (props.state.isError)
        return (<Error_data repeate={() => props.get_metadata(props.apikey)}/>)
    else if (props.state.isFetching)
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>);
    else if (!props.state.isReceived)
        props.get_metadata(props.apikey);

    if(props.personal.isError)
        return (<Error_data repeate={() => props.get_personal_data(props.apikey)} />)
    else if(props.personal.isFetching)
        return (
        <Backdrop open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>);
    else if(!props.personal.isReceived)
        props.get_personal_data(props.apikey);

    if (props.browserHistory.location.pathname === "/" && props.state.catalogs.length > 0)
        props.browserHistory.push("/catalog/" + props.state.catalogs[0].path + "/all");

    return (
        <Router history={props.browserHistory}>
            <div>

                {(props.item.uuid !== undefined && /\/item/.test(props.browserHistory.location.pathname) === false) ? (
                    <Dialog />) : null}
                    <Route path="/catalog/:catalog_path"
                           render={(nprops) => (
                               <Canvas browserHistory={props.browserHistory}><Catalog search={nprops.location.search} catalog_path={nprops.match.params.catalog_path}
                                        category_path={nprops.location.pathname}
                                        browserHistory={props.browserHistory}
                                                alert={props.alert}/></Canvas>)}/>
                    <Route path="/item/:catalog_path"
                           render={(nprops) => <Canvas browserHistory={props.browserHistory}><Item path={nprops.location.pathname}
                                                     alert={props.alert}/></Canvas>}/>
                    <Route path="/order" exact
                       component={(nprops) => <MakeOrder alert={props.alert} browserHistory={props.browserHistory} />}/>

                    <Route path="/orders" exact
                           component={(nprops) => <Canvas browserHistory={props.browserHistory}><Orders alert={props.alert} browserHistory={props.browserHistory} /></Canvas>}/>
            </div>
        </Router>
    );
}

const mapStateToProps = function (state) {
    return {
        'state': state.metadata,
        'item': state.item,
        'personal': state.personal,
        'apikey': state.auth.key
    }
}

const mapDispatchToProps = function (dispatch, state) {
    return {
        'get_metadata': (key) => {
            dispatch(get_metadata(key));
        },
        'get_personal_data': (key) => {
            dispatch(get_personal_data(key));
        },
        'set_personal_data': (key, value) => {
            dispatch(set_personal_data(key, value));
            dispatch(update_catalog_data());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalContainer);

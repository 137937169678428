import {AUTH_ERROR, REQUEST_METADATA, ERROR_METADATA, RECEIVE_METADATA, METADATA_SET_CRUMPS} from '../types'

let initial_value = {
    'isFetching': false,
    'isReceived': false,
    'isError': false,
    'catalogs': [],
    'crumps': []
}

const metadataReducer = (state = initial_value, action) => {

    switch (action.type) {
        case AUTH_ERROR:
            return Object.assign({}, initial_value);
        case REQUEST_METADATA:
            return Object.assign({}, state, {isFetching: true});
        case ERROR_METADATA:
            return Object.assign({}, state, {isFetching: false, 'isError': true});
        case RECEIVE_METADATA:
            return Object.assign({}, state, {isFetching: false, isReceived: true, 'isError': false}, action.data)
        default:
            return state;
    }
}

export default metadataReducer;

import {
	REQUEST_CARD,
	ERROR_CARD,
	RECEIVE_CARD,
	CARD_PUT,
	CARD_DEL,
	SYNC_CARD,
	AUTH_ERROR,
	AUTH_LOGOUT,
	FLUSH_CARD,
	FLUSH_CATALOG
} from '../types'
import {exportFields} from "../../func";

let card = {
	isFetching:false,
	isReceived:false,
	isError:false,
	sync:true,
	items: []
}

const cardReducer = (state = undefined,action) => {

	if(state === undefined)
		state = JSON.parse(JSON.stringify(card));

	let newState;
	let element;

	switch (action.type) {
		case AUTH_ERROR:
			return Object.assign({}, JSON.parse(JSON.stringify(card)));
		case FLUSH_CARD:
			return Object.assign({}, JSON.parse(JSON.stringify(card)),{isReceived:true,sync:false});
		case FLUSH_CATALOG:
			return Object.assign({}, JSON.parse(JSON.stringify(card)),{isReceived:true,sync:false});
		case AUTH_LOGOUT:
			return Object.assign({}, JSON.parse(JSON.stringify(card)));
		case REQUEST_CARD:
			newState = Object.assign({}, state,{isFetching:true,isError:false});
			return newState;
		case RECEIVE_CARD:
			newState = Object.assign({}, state,{isReceived:true,isError:false,isFetching:false,sync:true},action.data);
			return newState;
		case ERROR_CARD:
			newState = Object.assign({}, state,{isFetching:false,isError:true});
			return newState;
		case CARD_PUT:
			newState = Object.assign({}, state,{sync:false,isError:false});

			let newEl = Object.assign({}, exportFields(action.item,"art,code,file,file_ext,file_uuid,good,good_uuid,koef,pack,pack_uuid,type_uuid,variant_uuid,price,quantity,sel_count"),{quantity:action.item.sel_count});

			element = newState.items.filter((item) => {return item.good_uuid === action.item.good_uuid && item.type_uuid === action.item.type_uuid && item.variant_uuid === action.item.variant_uuid});

			if(element.length!==0)
				element[0].quantity = action.item.sel_count;
			else
				newState.items.push(newEl);
			return newState;


		case CARD_DEL:

			newState = Object.assign({}, state,{sync:false,isError:false});

			newState.items = newState.items.filter((item) => {return !(item.good_uuid === action.item.good_uuid && item.variant_uuid === action.item.variant_uuid && item.type_uuid === action.item.type_uuid)});

			return newState;

		case SYNC_CARD:

			newState = Object.assign({}, state,{isReceived:true,isError:false,isFetching:false,sync:true});

			return newState;


		default:
			return state;
	}
}

export default cardReducer;

import React from 'react';
import {useQuery} from "react-query";
import {GetAPI} from "./api/rest1C";
import {LinearProgress} from "@material-ui/core";

const TxtInfo = () => {

    const { isLoading,isFetching, error, data : messages, refetch } = useQuery(
        'MessageData2',
        ()=>GetAPI({url:"content/message2"})
    );

    if (isLoading || isFetching) return (<LinearProgress />)

    return (
        <div className="txt-info">{messages?.data?.attributes?.text}</div>
    )
}

export default TxtInfo;

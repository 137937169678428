import React from 'react';

import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';

import ShoppingCart from '@material-ui/icons/ShoppingCart';

import RemoveIcon from '@material-ui/icons/Remove';

import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';

import './add_to_card.css';


export default class add_to_Cart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {count:props.count,quantity:props.quantity,item:props.item,price:props.price,isEdit:false}
		this.timer = undefined;
		this.countRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.count!==this.props.count &&  this.props.count!==this.state.count)
		{
			this.setState(Object.assign({}, this.state,{count:this.props.count}));
		}
		if(this.countRef.current && prevProps.isEdit!==this.state.isEdit && this.state.isEdit)
			this.countRef.current.focus();
	}

	put(quantity) {

		if(quantity<0)
			quantity = 0;
		//else if(quantity>this.state.quantity)
		//	quantity = this.state.quantity;
		else if(quantity>0)
			quantity = Math.max(Math.trunc(quantity/this.props.min_count)*this.props.min_count,this.props.min_count);

		if(this.timer)
			clearTimeout(this.timer);

		this.setState(Object.assign({}, this.state,{count:quantity}),() => {this.timer = setTimeout(()=>{this.props.put(Object.assign(this.state.item,{price:this.state.price,sel_count:quantity}))},500)});

	}

	handleEdit = () => {
		this.setState(Object.assign({}, this.state,{isEdit:true}));
	}

	handleKeyPress = (event) => {
		if(event.charCode == 13){
			this.handleStopEdit();
		}
	}

	handleStopEdit = () => {
		this.setState(Object.assign({}, this.state,{isEdit:false}));
	}

	render() {

		if(this.state.price===0)
			return null;
		else if(this.state.count>0 || this.state.isEdit || this?.props?.iscard)
			return (
				<div className={`wrapper ${this.state.isEdit ? 'edit' : 'read'}`}>
					<IconButton onClick={(event)=> this.put(this.state.count-this.props.min_count)}><RemoveIcon /></IconButton>
					<input ref={this.countRef} onBlur={this.handleStopEdit} onKeyPress={this.handleKeyPress}  className="addcard_quantity" type="number" value={this.state.count} onChange={(event)=>this.put(event.target.value)} />
					<span className={"count"} onClick={this.handleEdit}>{this.state.count}</span>
					<IconButton onClick={(event)=> this.put(this.state.count+this.props.min_count)}><AddIcon /></IconButton>
				</div> );
		else
			return <Button variant="contained" color="primary" onClick={(event)=> this.put(this.props.min_count)}><ShoppingCart style={{color:"#fff"}} />В Корзину</Button>;
	}
}

import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import {get_catalog,sel_category} from '../redux/actions.js'
import Error_data from '../error_data'

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';

import './catalog_menu_items.css';
import Filters from "./filters";

function ListItems(props) {

	let className;

	if(props.level>0)
		className = "sub_menu";

	if(props.select)
		className = className+" select";

	if(!props?.items?.length)
		return null;

	return (
			<div style={{marginLeft:(props.level*15)+'px'}} className={className}>
				{
				props.items.map((item,index) => {
					return (
						<React.Fragment key={`menu_items_${item.category_uuid}`}>
							<NavLink to={"/catalog/"+item.path} key={index} onClick={(event)=>{props.select_category(item);}}>
								<ListItem button className={(props.sel_category.length>0 && props.sel_category[0]===item)?"select":null}>
									<ListItemText classes={props.classes} primary={item.category}  />
								</ListItem>
							</NavLink>
							<ListItems select={props.sel_category.filter((nowitem)=>nowitem===item).length>0} sel_category={props.sel_category}  select_category={props.select_category} level={props.level+1} items={item.children} classes={props.classes} />
						</React.Fragment>
					)})
				}
			</div>
	);
}

function CatalogContainer(props) {

	React.useEffect(() => {
	}, []);

	const sel_catalog = props.metadata.catalogs.ffilter((item) => {return props.uuid === item.catalog_uuid});

	var sel_path = undefined;

	if(sel_catalog)
	{

		if(props.state[sel_catalog.catalog_uuid] && props.state[sel_catalog.catalog_uuid].isError)
			return (<Error_data repeate={() => props.get_catalog(props.apikey,sel_catalog.catalog_uuid)} />)
		else if(props.state[sel_catalog.catalog_uuid] && props.state[sel_catalog.catalog_uuid].isFetching)
			return (<CircularProgress/>);
		else if(!props.state[sel_catalog.catalog_uuid] || !props.state[sel_catalog.catalog_uuid].isReceived)
		{
			props.get_catalog(props.apikey,sel_catalog.catalog_uuid);
		}
		else {
			sel_path = props.state[sel_catalog.catalog_uuid];
		}

		if(sel_path && /\/item/.test(props.browserHistory.location.pathname) && props.state.sel_category.length===0)
		{

			let res_regex;

			const regex = /\/item\/(.+)\//g;

			if ((res_regex = regex.exec(props.browserHistory.location.pathname)) !== null && res_regex.length>1 && sel_path.path[res_regex[1]]) {
				props.select_category(sel_path.path[res_regex[1]]);
			}

		}

	}

	if(sel_path)
		return (
				<React.Fragment>
					<ListItems sel_category={props.state.sel_category} select_category={props.select_category} level={0} items={sel_path.category} classes={props.classes} />
					<Filters sel_catalog={sel_catalog} />
				</React.Fragment>
		);
	else
		return "";


}

const mapStateToProps = function(state) {
	return {
	  	'metadata':state.metadata,
	  	'state':state.catalog,
	  	'apikey':state.auth.key
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
	  	'get_catalog': (key,uuid) => {

		  	dispatch(get_catalog(key,uuid));

		},
		'select_category': (item) => {

		  	dispatch(sel_category(item));

		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CatalogContainer);

import {ADD_FILTER, DEL_FILTER, SET_FILTER} from '../types'

let initial_auth = {
    search: "",
    filters: []
}

const filtersReducer = (state = initial_auth, action) => {

    switch (action.type) {

        case SET_FILTER :
            return Object.assign({}, state, action.data);
        case ADD_FILTER : {
            const newState = Object.assign({},state);
            newState.filters.push({id:action.filter,value:action.value?.value_uuid,title:action.value?.value});
            return newState;
        }
        case DEL_FILTER : {
            const newState = Object.assign({},state);
            const el = newState.filters.ffilter((item=>item.id === action.filter && item.value === action.value));
            if(el)
                newState.filters.splice(newState.filters.indexOf(el),1);
            return newState;
        }
            //return Object.assign({}, state, action.data);
        default:
            return state;
    }
}

export default filtersReducer;

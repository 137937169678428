import React from 'react';
import {connect} from 'react-redux';
import {get_metadata, get_personal_data, set_personal_data, update_catalog_data} from './redux/actions.js'
import Card from './card/card'

import Slideshow from "./slideshow";
import ScrollMessages from "./scrollmessages";
import Userbar from "./user";
import Category_list from "./catalog/category_list";
import TxtInfo from "./txtinfo";

function Canvas({children,browserHistory}) {

    return (
            <>
                <div className="header">
                    <Card />
                    <Userbar />
                    <Slideshow />
                    <ScrollMessages />
                    <TxtInfo />
                </div>
                <div className="main">
                    <Category_list browserHistory={browserHistory} />
                    <main>
                        {children}
                    </main>
                </div>
            </>
    );
}

export default Canvas;

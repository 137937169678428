import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {order_update, get_order, new_order, flush_card, sync_card, get_card, card_put} from './redux/actions.js'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";
import Add_to_cart from "./catalog/add_to_cart";
import {round} from "./func";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel, ListItemIcon, Menu, MenuItem, Paper,
    Radio,
    RadioGroup, Select,
    TextareaAutosize
} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {useMutation} from "react-query";
import {newOrderAPI} from "./api/rest1C";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function OrderContainer(props) {

    const sum = props.card.items.reduce((accumulator, currentValue) => {
        accumulator.sum = accumulator.sum + currentValue.quantity * currentValue.price;
        accumulator.quantity = accumulator.quantity + currentValue.quantity;
        return accumulator;
    }, {sum: 0, quantity: 0});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        if (!props.card.isError && !props.card.isReceived && !props.card.isFetching)
            props.get_card(props.apikey);

        if (!props.card.isError && !props.card.isFetching && props.card.sync === false)
            props.sync_card(props.apikey, props.card.items);
    },[props.card.sync]);

    const [valid,setValid] = useState({});
    const [f_name,setName] = useState('');
    const [f_phone,setPhone] = useState('');
    const [f_count,setCount] = useState("0");
    const [f_type,setType] = useState("self");
    const [f_addr,setAddr] = useState(props?.personal?.partner?.addr ? props?.personal?.partner?.addr[0] : "");
    const [f_comment,setComment] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const newOrder = useMutation(newOrderAPI, {
        onSuccess: data => {props.flush_card();props.browserHistory.push("/orders/")},
        onError: data => props.alert.error(data),
    });

    const handleNewOrder = (event) => {
        event.preventDefault();

        const valid = {};
        if(f_name === "")
            valid.name = true;
        if(f_phone === "")
            valid.phone = true;

        if(f_type === "addr" && f_addr === "")
            valid.addr = true;

        setValid(valid);

        if(Object.keys(valid).length > 0)
            return;

        const order = {
            name:f_name,
            phone:f_phone,
            type:f_type,
            addr:f_addr,
            comment:f_comment,
            agreement: props?.personal?.sel_agreement,
            count:f_count,
            card:props.card?.items
        }

        newOrder.mutate({key:props?.apikey,...order});

    }

    if(newOrder.isLoading)
        return (<CircularProgress/>)

    return (
        <div className="order">
            <div className="top">
                <div className="back">
                    <IconButton onClick={()=>props.browserHistory.goBack()}>
                        <ArrowBackIcon/>
                    </IconButton>
                </div>
                <h1>Ваш заказ</h1>
                <div className="close">

                </div>
            </div>
            <div className="content">
                <div className="fields">

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} className="alignLeft">
                            <TextField
                                variant="outlined"
                                style={{width:"100%"}}
                                label="Имя"
                                error={valid.name}
                                helperText={"Обязательное поле"}
                                value={f_name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="alignLeft">
                            <InputLabel>Телефон</InputLabel>
                            <TextField
                                placeholder={"+ 7 921 999 00 00"}
                                variant="outlined"
                                style={{width:"100%"}}
                                error={valid.phone}
                                helperText={"Обязательное поле"}
                                value={f_phone}
                                onChange={(event) => setPhone(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} className="alignLeft">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Укажите количество газовых баллонов</FormLabel>
                                <RadioGroup
                                    aria-label="count"
                                    name="gender1"
                                    value={f_count}
                                    onChange={(event) => setCount(event.target.value)}
                                >
                                    <FormControlLabel value="0" control={<Radio />} label="0" />
                                    <FormControlLabel value="1" control={<Radio />} label="1" />
                                    <FormControlLabel value="2" control={<Radio />} label="2" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} className="alignLeft">
                            <FormControl>
                                <FormLabel component="legend">Тип доставки</FormLabel>
                                <Select
                                    onChange={event => setType(event.target.value)}
                                    value={f_type}>
                                    <MenuItem id="self" key={0} value="self">Самовывоз</MenuItem>
                                    <MenuItem id="addr" key={1} value="addr">Доставка до адреса</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            f_type === "addr" ?
                                (<Grid item xs={12} md={12} className="alignLeft">
                                    <InputLabel>Адрес доставки</InputLabel>
                                    <Paper className={"addr"}>
                                        <InputBase
                                            placeholder="Адрес доставки"
                                            value={f_addr}
                                            multiline={true}
                                            onChange={(event) => setAddr(event.target.value)}
                                        />
                                        <IconButton aria-label="open" onClick={handleClick}>
                                            <ArrowDropDown />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="addr-menu"
                                            className="addr-menu"
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            {
                                                props?.personal?.partner?.addr?.map((item,index)=>(
                                                    <MenuItem key={index} onClick={()=>setAddr(item)}>
                                                        {item}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </Paper>
                                    {/*<TextField*/}
                                    {/*    variant="outlined"*/}
                                    {/*    style={{width: "100%"}}*/}
                                    {/*    error={valid.addr}*/}
                                    {/*    helperText={"Обязательное поле"}*/}
                                    {/*    value={f_addr}*/}
                                    {/*    onChange={(event) => setAddr(event.target.value)}*/}
                                    {/*/>*/}
                                </Grid>):null
                        }
                        <Grid item xs={12} md={12} className="alignLeft">
                            <InputLabel>Комментарий к заказу</InputLabel>
                            <TextareaAutosize
                                value={f_comment}
                                onChange={(event) => setComment(event.target.value)}
                                minRows={5}
                                style={{width:"100%"}}/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <button onClick={handleNewOrder} className={"order"}>Оформить заявку</button>
                        </Grid>
                    </Grid>
                </div>
                <div className="data">
                    <div className="wrapper">
                        {
                            props?.card?.items?.map((item)=>
                                (
                                    <div className="product" key={`card_${item.good_uuid}`}>
                                        <div className="img" style={{backgroundImage: `url(/photo/good/s3/${item?.file_uuid}.${item?.file_ext})`}}></div>
                                        <div className="name">
                                            <div className="art">{item.art}</div>
                                            <div className="vol">Объём: <span>{item.pack}</span></div>
                                        </div>
                                        <div className="market_add"><Add_to_cart iscard={true} count={item.quantity} min_count={item.koef} price={item.price}
                                                                                 quantity={item.quantity} item={item} put={props.card_put}/></div>
                                        <div>{round(item.quantity * item.price)} ₽</div>
                                        <div className="del" onClick={(event) => props.card_del(item)}>
                                            <img src="/images/arrows_circle_remove.svg" />
                                        </div>
                                    </div>
                                )
                            )
                        }
                        <div className="sum">Сумма: {round(sum.sum)} ₽</div>
                        <button onClick={handleNewOrder} className={"mob order"}>Оформить заявку</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        'state': state.order,
        'card': state.card,
        'personal': state.personal,
        'apikey': state.auth.key
    }
}

const mapDispatchToProps = function (dispatch, state) {
    return {
        'order_update': (data) => {
            dispatch(order_update(data));
        },
        'get_order': (item, card, agreement) => {
            dispatch(get_order(item, card, agreement));
        },
        'new_order': (item, order) => {
            dispatch(new_order(item, order));
        },
        'flush_card': (item) => {
            dispatch(flush_card(item));
        },
        'get_card': (key) => {
            dispatch(get_card(key));
        },
        'sync_card': (key, items) => {
            dispatch(sync_card(key, items));
        },
        'card_put': (item) => {
            dispatch(card_put(item));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);

import React, {useState} from "react";
import {add_filter, get_catalog, remove_filter, sel_category, set_filter} from "../redux/actions";
import {connect} from "react-redux";
import {Checkbox, FormControlLabel} from "@material-ui/core";

function Enum({title,items,filter,add_filter,remove_filter}){

    const [isOpen,setOpen] = useState(false);
    const [all,setAll] = useState(items?.length <10);
    const handleOpen = () => {
        setOpen(prev=>!prev);
    }
    const handleSetFilter = (el,item) => {
        if(el?.target?.checked)
            add_filter(item);
        else
            remove_filter(item?.value_uuid);
    }

    return (
        <>
            <h1 onClick={handleOpen} className={`${isOpen ? 'opened' : 'closed'}`}>{title}</h1>
            <ul className={`${isOpen ? 'opened' : 'closed'}`}>
                {
                    items?.map((item,index)=> (all || index<10) && (
                        <li key={`value_${item?.value_uuid}`}>
                            <FormControlLabel
                                control={<Checkbox color="default" name={item?.value} checked={filter?.filters?.ffilter((el)=>el.value === item?.value_uuid) ? true : false} onChange={(el)=>handleSetFilter(el,item)} />}
                                label={item?.value}
                            />
                        </li>
                    ))
                }
                {
                    !all && (
                        <li className={"all"} onClick={()=>setAll(true)}>Показать все</li>
                    )
                }
            </ul>
        </>
    )
}

function Filters({sel_catalog,catalog,filter,add_filter,remove_filter}) {

    const [isOpen,setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(prev=>!prev);
    }

    if(!sel_catalog?.catalog_uuid)
        return null;

    let _catalog = catalog[sel_catalog?.catalog_uuid];

    if(!_catalog?.filters)
        return null;

    return (
        <div className={"filters"}>
            <a className={`openfilter ${isOpen ? 'opened' : 'closed'}`} onClick={handleOpen}>Фильтры</a>
            <div className={`wrapper ${isOpen?'opened':'closed'}`}>
                {
                    _catalog?.filters?.map((item)=>
                        (
                            <React.Fragment key={`filter_${item?.filter_uuid}`}>
                                {
                                    item?.type === "CatalogRef.ЗначенияСвойствОбъектов" ? <Enum title={item?.filter_title} add_filter={(value)=>add_filter(item?.filter_uuid,value)} remove_filter={(value)=>remove_filter(item?.filter_uuid,value)} filter={filter} items={item?.enum}/> : null
                                }
                            </React.Fragment>
                        ))
                }
            </div>
            {filter?.filters.length > 0 && (
                <div className={"elements"}>
                    {
                        filter?.filters.map((item) => (
                            <div><a onClick={()=>remove_filter(item?.id,item.value)}>✕</a>&nbsp;{item?.title}</div>
                        ))
                    }
                </div>)
            }
        </div>
    )
}

const mapStateToProps = function(state) {
    return {
        'catalog':state.catalog,
        'filter':state.filter,
    }
}

const mapDispatchToProps = function(dispatch,state) {
    return {
        'add_filter': (filter,value) => {
            dispatch(add_filter(filter,value));
        },
        'remove_filter': (filter,value) => {
            dispatch(remove_filter(filter,value));
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Filters);

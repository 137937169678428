import {
    REQUEST_ORDER,
    ERROR_ORDER,
    RECEIVE_ORDER,
    SYNC_ORDER,
    AUTH_ERROR,
    AUTH_LOGOUT,
    ORDER_UPDATE,
    FLUSH_ORDER,
    FLUSH_CATALOG
} from '../types'

let order = {
    isFetching: false,
    isReceived: false,
    isError: false,
    isValidate: true,
    uuid: undefined,
    sync: true,
    items: [],
    isNew: false,
    validate_errors: {},
    firm: undefined,
    delivery: undefined,
    agrement: undefined,
    doc: undefined,
    doc_uuid: undefined
}

const cardReducer = (state = undefined, action) => {

    if (state === undefined)
        state = JSON.parse(JSON.stringify(order));

    let newState;
    let element;

    switch (action.type) {
        case AUTH_ERROR:
            return Object.assign({}, JSON.parse(JSON.stringify(order)));
        case AUTH_LOGOUT:
            return Object.assign({}, JSON.parse(JSON.stringify(order)));
        case REQUEST_ORDER:
            newState = Object.assign({}, state, {isFetching: true, isError: false});
            return newState;
        case RECEIVE_ORDER:
            newState = Object.assign({}, state, {
                isReceived: true,
                isError: false,
                isFetching: false,
                sync: true
            }, action.data);
            return newState;
        case ERROR_ORDER:
            newState = Object.assign({}, state, {isFetching: false, isError: true});
            return newState;
        case SYNC_ORDER:
            newState = Object.assign({}, state, {isReceived: true, isError: false, isFetching: false, sync: true});
            return newState;
        case ORDER_UPDATE:
            newState = Object.assign({}, state, action.data);
            return newState;
        case FLUSH_ORDER:
            newState = Object.assign({}, order);
            return newState;
        case FLUSH_CATALOG:
            newState = Object.assign({}, order);
            return newState;
        default:
            return state;
    }
}

export default cardReducer;
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

class LazyImage extends React.Component {
  state = { src: null };

  className = null;

  componentDidMount() {
    const { src } = this.props;

    this.className = this.props.className;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
	if(this.state.src)
  		return (<img className={this.className} src={this.state.src} />);
  	else
  		return (<CircularProgress />);
  }
}

export default LazyImage;

import React from 'react';
import {useQuery} from "react-query";
import {GetAPI} from "./api/rest1C";
import {LinearProgress} from "@material-ui/core";

const ScrollMessages = () => {

    const { isLoading,isFetching, error, data : messages, refetch } = useQuery(
        'MessageData',
        ()=>GetAPI({url:"content/message"})
    );

    if (isLoading || isFetching) return (<LinearProgress />)

    if(!messages?.data?.attributes?.text)
        return null;

    return (
        <div className="scroll-container">
            <div className="container">
                {
                    Array(6).fill(0).map(
                        (item,i)=> (
                            <div key={i}>
                                {messages?.data?.attributes?.text}
                                <svg role="presentation" width="6" height="6" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="5.11351" cy="4" rx="4.16918" ry="4" fill="#ffffff"></ellipse>
                                </svg>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default ScrollMessages;

import React from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import {get_catalog,sel_category} from '../redux/actions.js'
import Error_data from '../error_data'
import Catalog_element from './catalog_element'
import Items_list from './items_list'

import Dialog from './dialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import './catalog.css';

import './dialog.css';

function CatalogContainer(props) {

	var sel_path = undefined;

	const sel_catalog = props.metadata.catalogs.ffilter((item) => {return props.catalog_path === item.path});

	if(!sel_catalog)
	{
		return (
			<h1>Выберите каталог</h1>
		);
	}

	if(props.state[sel_catalog.catalog_uuid] && props.category_path)
	{
		let sel_path_str = props.category_path.replace(/^\/catalog\//g,'').replace(/\/$/g,'');
		if(sel_path_str)
		{
			sel_path = props.state[sel_catalog.catalog_uuid].path[sel_path_str];
			if(sel_path && props.state.sel_category.length===0)
			{
				setTimeout(()=>props.select_category(sel_path));
			}
		}
	}

	if(!sel_path && sel_catalog && props.state[sel_catalog.catalog_uuid])
	{
		sel_path = {category:sel_catalog.catalog,category_uuid:sel_catalog.catalog_uuid,children:props.state[sel_catalog.catalog_uuid].category};
	}

	if(props.state[sel_catalog.catalog_uuid] && props.state[sel_catalog.catalog_uuid].isError)
		return (<Error_data repeate={() => props.get_catalog(props.apikey,sel_catalog.catalog_uuid)} />)
	else if(props.state[sel_catalog.catalog_uuid] && props.state[sel_catalog.catalog_uuid].isFetching)
		return (<CircularProgress/>);
	else if(!props.state[sel_catalog.catalog_uuid] || !props.state[sel_catalog.catalog_uuid].isReceived)
	{
		props.get_catalog(props.apikey,sel_catalog.catalog_uuid);
	}

	if(sel_path === undefined)
		return (
			<h1>Выберите каталог</h1>
		);

	return (
		<React.Fragment>
		{sel_path.goods && (
			<Items_list search={props.search} catalog={sel_catalog.path} category={sel_path} state={props.state} store={props.store} browserHistory ={props.browserHistory} alert={props.alert} /> ) }
		</React.Fragment>
	);

}

const mapStateToProps = function(state) {
	return {
	  	'metadata':state.metadata,
	  	'state':state.catalog,
	  	'apikey':state.auth.key
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
	  	'get_catalog': (key,uuid) => {

		  	dispatch(get_catalog(key,uuid));

		},
		'select_category': (item) => {

		  	dispatch(sel_category(item));

		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CatalogContainer);

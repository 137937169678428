import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from 'redux-thunk'
import authReducer from './reducers/auth';
import metadataReducer from './reducers/metadata';
import personalReducer from './reducers/personal';
import catalogReducer from './reducers/catalog';
import itemsReducer from './reducers/items';
import itemReducer from './reducers/item';
import storeReducer from './reducers/store';
import cardReducer from './reducers/card';
import orderReducer from './reducers/order';
import alertReducer from './reducers/alert';
import filtersReducer from "./reducers/filters";

let reducers = combineReducers({
    auth: authReducer,
    metadata: metadataReducer,
    personal: personalReducer,
    catalog: catalogReducer,
    items: itemsReducer,
    item: itemReducer,
    store: storeReducer,
    card: cardReducer,
    order: orderReducer,
    alert: alertReducer,
    filter: filtersReducer,
});

let store = createStore(reducers, applyMiddleware(thunk));

export default store;

import React from 'react';
import {positions, Provider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Home from './home';
import {createBrowserHistory} from 'history';
import './prototype';
import './App.css';
import './style.scss';
import BackgoundProvider from "./providers/background";
import {
    QueryClientProvider,
} from 'react-query'
import {QueryClient} from "react-query";

const alert_options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
};

const browserHistory = createBrowserHistory();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        },
    },
})

function App(props) {

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Provider template={AlertTemplate} {...alert_options} containerStyle={{zIndex: 1500}}>
                    <Home browserHistory={browserHistory}/>
                </Provider>
            </QueryClientProvider>
        </div>
    );
}

export default App;

import {
    AUTH_LOGIN,
    AUTH_ON_LOGIN,
    AUTH_SUCSESS,
    AUTH_ERROR,
    AUTH_LOGOUT,
    FLUSH_CATALOG,
    SET_FILTER,
    ADD_FILTER, DEL_FILTER
} from './types'
import {REQUEST_METADATA, ERROR_METADATA, RECEIVE_METADATA} from './types'
import {REQUEST_CATALOG, ERROR_CATALOG, RECEIVE_CATALOG, SEL_CATEGORY} from './types'
import {
    REQUEST_ITEMS_DATA,
    REQUEST_MORE_ITEMS_DATA,
    ERROR_ITEMS_DATA,
    RECEIVE_ITEMS_DATA,
    RECEIVE_MORE_ITEMS_DATA,
    FLUSH_ITEMS_DATA,
    SET_ITEM_COUNT,
    SET_LIST_OPTION
} from './types'
import {
    REQUEST_PERSONAL_DATA,
    ERROR_PERSONAL_DATA,
    RECEIVE_PERSONAL_DATA,
    SET_PERSONAL_DATA,
    SET_PERSONAL_OPTION
} from './types'

import {REQUEST_CARD, ERROR_CARD, RECEIVE_CARD, CARD_PUT, CARD_DEL, SYNC_CARD, FLUSH_CARD} from './types'

import {REQUEST_ITEM, ERROR_ITEM, RECEIVE_ITEM, FLUSH_ITEM, SET_ITEM_PHOTO} from './types'

import {REQUEST_STORE, ERROR_STORE, RECEIVE_STORE, FLUSH_STORE, SET_STORE_COUNT} from './types'

import {ORDER_UPDATE, REQUEST_ORDER, RECEIVE_ORDER, ERROR_ORDER, FLUSH_ORDER} from './types'

import axios from 'axios';

export function auth_login(username, password) {
    return dispatch => {

        dispatch({type: AUTH_ON_LOGIN});

        let data = {username: username, password: password};

        axios.post(window.apiBaseUrl + 'authrorisation', JSON.stringify(data)).then(function (response) {
            if (response.status === 200) {
                if (response.data.key) {
                    dispatch({'type': 'AUTH_SUCSESS', 'key': response.data.key});
                } else {
                    dispatch({'type': 'AUTH_ERROR'});
                    dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка авторизации'});
                }
            } else {
                dispatch({'type': 'AUTH_ERROR'});
                dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка авторизации'});
            }
        }).catch((error) => {
            dispatch({'type': 'AUTH_ERROR'});
            dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
        });
    }
}

export function auth(key) {
    return {'type': AUTH_SUCSESS, 'key': key};

}

export function get_metadata(key) {
    return (dispatch) => {

        dispatch({type: REQUEST_METADATA});

        let data = {key: key};

        axios.post(window.apiBaseUrl + 'metadata', JSON.stringify(data)).then(function (response) {


            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_METADATA, 'data': response.data.data});
                } else {
                    dispatch({'type': ERROR_METADATA});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_METADATA});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function get_catalog(key, uuid) {
    return (dispatch) => {

        dispatch({type: REQUEST_CATALOG, uuid: uuid});

        let data = {key: key, uuid: uuid};

        axios.post(window.apiBaseUrl + 'catalog', JSON.stringify(data)).then(function (response) {
            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_CATALOG, 'uuid': uuid, 'data': response.data.data});
                } else {
                    dispatch({'type': ERROR_CATALOG, 'uuid': uuid});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_CATALOG, 'uuid': uuid});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function sel_category(item) {
    return {'type': SEL_CATEGORY, item: item};

}

export function get_category_tree(item) {
    let array = [];
    if (item)
        return array.concat(item, get_category_tree(item.parent));
}

export function get_path(arr, pref = "", parent = undefined, parent_crups = undefined) {
    var path = {};
    arr.forEach(function (item, i, arr) {
        item.path = pref + item.path;
        item.parent = parent;

        if (parent_crups === undefined)
            item.crumps = [];
        else
            item.crumps = [...parent_crups];

        item.crumps.push({name: item.category, path: item.path, item: item});

        path[item.path] = item;
        if (item.children && item.children.length > 0)
            path = Object.assign(path, get_path(item.children, item.path + '/', item, item.crumps));
    });
    return path;
}

export function get_catalog_items(key, uuid, page, personal) {
    return (dispatch) => {

        if(!personal.sel_agreement)
            return;

        //dispatch({type: FLUSH_ITEM});

        dispatch({type: REQUEST_ITEMS_DATA, uuid: uuid, page: page});

        let data = {
            key: key,
            uuid: uuid,
            page_size: personal.list_size,
            order: personal.list_order,
            page: page,
            agreement: personal.sel_agreement
        };

        axios.post(window.apiBaseUrl + 'catalog_items', JSON.stringify(data)).then(function (response) {
            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_ITEMS_DATA, 'data': response.data.data});
                } else {
                    dispatch({'type': ERROR_ITEMS_DATA, 'uuid': uuid});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_ITEMS_DATA, 'uuid': uuid});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function get_more_catalog_items(key, uuid, page, personal) {
    return (dispatch) => {

        dispatch({type: REQUEST_MORE_ITEMS_DATA, uuid: uuid, page: page});

        let data = {
            key: key,
            uuid: uuid,
            page_size: personal.list_size,
            order: personal.list_order,
            page: page,
            agreement: personal.sel_agreement
        };

        axios.post(window.apiBaseUrl + 'catalog_items', JSON.stringify(data)).then(function (response) {
            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_MORE_ITEMS_DATA, 'data': response.data.data});
                } else {
                    dispatch({'type': ERROR_ITEMS_DATA, 'uuid': uuid});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_ITEMS_DATA, 'uuid': uuid});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function get_personal_data(key) {
    return (dispatch) => {

        dispatch({type: REQUEST_PERSONAL_DATA});

        let data = {key: key};

        axios.post(window.apiBaseUrl + 'get_personal_data', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_PERSONAL_DATA, 'data': response.data.data});
                } else {
                    dispatch({'type': ERROR_PERSONAL_DATA});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_PERSONAL_DATA});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function change_list_option(value) {
    return {'type': SET_LIST_OPTION, value};

}

export function flush_items() {
    return {'type': FLUSH_ITEMS_DATA};

}

export function items_select_count(index, value) {
    return {'type': SET_ITEM_COUNT, 'index': index, 'value': value};
}

export function getAllUrlParams(queryString) {

    var obj = {};

    if (queryString && queryString[0] === '?') {

        queryString = queryString.slice(1);

        queryString = queryString.split('#')[0];

        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split('=');

            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function (v) {
                paramNum = v.slice(1, -1);
                return '';
            });

            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            paramName = paramName.toLowerCase();

            if (typeof paramValue === 'string')
                paramValue = paramValue.toLowerCase();

            if (obj[paramName]) {
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                if (typeof paramNum === 'undefined') {
                    obj[paramName].push(paramValue);
                } else {
                    obj[paramName][paramNum] = paramValue;
                }
            } else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
}

export function get_card(key) {
    return (dispatch) => {

        dispatch({type: REQUEST_CARD});

        let data = {key: key};

        axios.post(window.apiBaseUrl + 'get_card', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_CARD, 'data': response.data});
                } else {
                    dispatch({'type': ERROR_CARD});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({'type': ERROR_CARD});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function card_put(item) {
    return (dispatch) => {
        if (item.sel_count === 0) {
            dispatch({type: CARD_DEL, item: item});
            return;
        }
        if (item.price === 0) {
            dispatch({type: 'ALERT_ERROR_ADD', text: 'У товара нет цены'});
            return;
        }

        dispatch({type: CARD_PUT, item: item})
    }
}

export function card_del(item) {
    return (dispatch) => {
        dispatch({type: CARD_DEL, item: item});
    }
}

export function sync_card(key, items) {
    return (dispatch) => {

        dispatch({type: REQUEST_CARD});

        let data = {key: key, items: items};

        axios.post(window.apiBaseUrl + 'set_card', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': SYNC_CARD});
                } else {
                    dispatch({type: ERROR_CARD});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({type: ERROR_CARD});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function select_item(key, item, variant, catalog) {
    return (dispatch) => {

        dispatch({type: REQUEST_ITEM, item: item, variant:variant, catalog: catalog});

        let data = {key: key, item: item, variant:variant, catalog: catalog};

        axios.post(window.apiBaseUrl + 'item', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_ITEM, 'data': response.data.data});
                } else {
                    dispatch({type: ERROR_ITEM});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({type: ERROR_ITEM});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function flush_item() {
    return {'type': FLUSH_ITEM};

}

export function get_stote(key, item, agrement,catalog=undefined) {
    return (dispatch) => {

        dispatch({type: REQUEST_STORE});

        //dispatch({'type': RECEIVE_STORE, 'data': {data:123}});

        let data = {key: key, item: item, agrement: agrement,catalog:catalog};

        axios.post(window.apiBaseUrl + 'store', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_STORE, 'data': response.data.data});
                } else {
                    dispatch({type: ERROR_STORE});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({type: ERROR_STORE});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}


export function store_select_count(value) {
    return {'type': SET_STORE_COUNT, 'value': value};
}

export function set_personal_data(key, value) {

    return (dispatch) => {

        dispatch({type: SET_PERSONAL_DATA, value});

        let data = {key: key, value};

        axios.post(window.apiBaseUrl + 'set_personal_data', JSON.stringify(data)).then(function (response) {
        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка авторизации'});
            } else {
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }

}

export function change_option(value) {
    return {'type': SET_PERSONAL_OPTION, 'value': value};

}

export function select_item_photo(item) {
    return {'type': SET_ITEM_PHOTO, item: item};

}

export function order_update(data) {
    return {'type': ORDER_UPDATE, data};
}

export function get_order(key, card, agrement = undefined, uuid = undefined) {
    return (dispatch) => {

        dispatch({type: REQUEST_ORDER});

        let data = {key: key, card: card, agrement: agrement, uuid: uuid};

        axios.post(window.apiBaseUrl + 'order', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_ORDER, 'data': response.data.data});
                } else {
                    dispatch({type: ERROR_ORDER});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({type: ERROR_ORDER});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function new_order(key, order) {
    return (dispatch) => {

        dispatch({type: REQUEST_ORDER});

        let validate_errors = {};

        let onValidate = true;

        if (!order.firm) {
            validate_errors.firm = "Не заполнен контрагент";
            onValidate = false;
        }

        if (!order.delivery) {
            validate_errors.delivery = "Не заполнен тип доставки";
            onValidate = false;
        }

        if (order.delivery === "addr" && !order.addr) {
            validate_errors.addr = "Не заполнен адрес доставки";
            onValidate = false;
        }

        if (!onValidate) {
            dispatch({type: 'ALERT_ERROR_ADD', text: "Ошибка проверки формы"});
            dispatch({
                type: ORDER_UPDATE,
                data: {isValidate: false, isFetching: false, validate_errors: validate_errors}
            });
            return;
        }

        let data = {key: key, order: order};

        axios.post(window.apiBaseUrl + 'new_order', JSON.stringify(data)).then(function (response) {

            if (response.status === 200) {
                if (response.data.result) {
                    dispatch({'type': RECEIVE_ORDER, 'data': response.data.data});
                } else {
                    dispatch({type: ERROR_ORDER});
                    if (response.data && response.data.message)
                        dispatch({type: 'ALERT_ERROR_ADD', text: response.data.message});
                    else
                        dispatch({type: 'ALERT_ERROR_ADD', text: 'Ошибка получения данных с сервера'});
                }
            }

        }).catch((error) => {

            if (error.response && error.response.status === 401) {
                dispatch({'type': AUTH_ERROR});
            } else {
                dispatch({type: ERROR_ORDER});
                dispatch({type: 'ALERT_ERROR_ADD', text: error.message});
            }
        });

    }
}

export function flush_order() {
    return {'type': FLUSH_ORDER};
}

export function flush_card() {
    return {'type': FLUSH_CARD};
}

export function update_catalog_data() {
    return {'type': FLUSH_CATALOG};
}

export function set_filter(data) {
    return {'type': SET_FILTER,data};
}

export function add_filter(filter,value) {
    return {'type': ADD_FILTER,filter,value};
}

export function remove_filter(filter,value) {
    return {'type': DEL_FILTER,filter,value};
}

import React, {useState} from 'react';
import { useQuery} from "react-query";
import { OrdersAPI} from "../api/rest1C";
import CircularProgress from "@material-ui/core/CircularProgress";
import {LinearProgress, TextField} from "@material-ui/core";
import {flush_card, get_card, get_order, new_order, order_update, sync_card} from "../redux/actions";
import {connect} from "react-redux";
import {round} from "../func";

function OrdersContainer({apikey})
{

    const [startDate,setStartDate] = useState(new Date().calcday(-7));
    const [endDate,setEndDate] = useState(new Date());

    const { isLoading,isFetching, error, data, refetch } = useQuery(
        'ordersData',
        ()=>OrdersAPI({key:apikey,startDate:startDate,endDate:endDate})
    );

    const handleChageStartDate = (val) => {
        setStartDate(new Date(val.target.value));
        refetch();
    }

    const handleChageEndDate = (val) => {
        setEndDate(new Date(val.target.value));
        refetch();
    }

    if (isLoading || isFetching) return (<LinearProgress />)

    if (error) return <p>Ошибка получения данных: {error.message}</p>;

    return (
        <div className="orders">
            <h3>Заказы</h3>
            <div className="period">
                <TextField
                    id="date"
                    label="С"
                    type="date"
                    defaultValue={startDate.s_datestr()}
                    onChange={handleChageStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="date"
                    label="По"
                    type="date"
                    defaultValue={endDate.s_datestr()}
                    onChange={handleChageEndDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Статус</th>
                        <th>Номер</th>
                        <th>Дата</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data?.data?.map(item=>(
                        <tr key={item?.ref_uuid}>
                            <td>{item?.status}</td>
                            <td>{item?.num}</td>
                            <td>{item?.date?.datestr()}</td>
                            <td>{round(item?.sum)} ₽</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        'personal': state.personal,
        'apikey': state.auth.key
    }
}

export default connect(mapStateToProps)(OrdersContainer);

import {
    AUTH_ERROR,
    AUTH_LOGOUT,
    REQUEST_STORE,
    ERROR_STORE,
    RECEIVE_STORE,
    FLUSH_STORE,
    FLUSH_ITEM,
    SET_STORE_COUNT
} from '../types'


let item = {
    uuid: undefined,
    good: undefined,
    good_uuid: undefined,
    price: undefined,
    quantity: undefined,
    type: undefined,
    type_uuid: undefined,
    unit: undefined,
    unit_uuid: undefined,
    sel_count: undefined
}

let items = {
    isFetching: false,
    isReceived: false,
    isError: false,
    items: []
}


const itemReducer = (state = undefined, action) => {

    if (state === undefined)
        state = JSON.parse(JSON.stringify(items));

    let newState;

    switch (action.type) {
        case AUTH_ERROR || AUTH_LOGOUT:
            return Object.assign({}, item);
        case REQUEST_STORE:
            newState = Object.assign({}, state, {isFetching: true, isError: false});
            return newState;
        case RECEIVE_STORE:
            newState = Object.assign({}, items, {isReceived: true, isFetching: false, isError: false}, action.data);
            return newState;
        case ERROR_STORE:
            newState = Object.assign({}, state, {isFetching: false, isError: true});
            return newState;
        case FLUSH_STORE:
            newState = Object.assign({}, item);
            return newState;
        case FLUSH_ITEM:
            newState = Object.assign({}, item);
            return newState;
        //case SET_STORE_COUNT:
        //newState = Object.assign({}, state, {sel_count:action.value});
        //return newState;
        default:
            return state;
    }
}

export default itemReducer;
import {AUTH_ON_LOGIN, AUTH_SUCSESS, AUTH_ERROR, AUTH_LOGOUT} from '../types'

let initial_auth = {
    isFetching: false,
    isError: false,
    isLogin: false,
    key: undefined
}

const authReducer = (state = initial_auth, action) => {

    switch (action.type) {

        case AUTH_ON_LOGIN :
            localStorage.removeItem('authKey');
            return Object.assign({}, state, {'isFetching': true});
        case AUTH_SUCSESS :
            localStorage.setItem('authKey', action.key);
            return Object.assign({}, state, {'isLogin': true, 'isFetching': false, 'key': action.key});
        case AUTH_ERROR :
            localStorage.removeItem('authKey');
            return Object.assign({}, state, {'isFetching': false, 'isLogin': false});
        case AUTH_LOGOUT :
            localStorage.removeItem('authKey');
            return Object.assign({}, state, {'isFetching': false, 'isLogin': false});
        default:
            return state;
    }
}

export default authReducer;
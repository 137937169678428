import React from 'react';
import { connect } from 'react-redux';
import Lazy_image from './lazy_image';
import Store from './store';
import {select_item,select_item_photo} from '../redux/actions.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Error_data from '../error_data'
import './item.css';
import {location_info} from "../func";

function ItemContainer(props) {

	const select_item = (uuid,variant,catalog) =>
	{
		props.select_item(props.apikey,uuid,variant,props.metadata.catalogs.reduce((inst,prev) => {if(prev.path===catalog) return prev.catalog_uuid},""));
	}

	const Photo = () => {
		return (
			<div className="photo_gallery">
				<div className="main_photo">
					{props.state.file && <Lazy_image placeholder="/images/photo-loader.gif" src={"/photo/good/l1/"+props.state.file_uuid+"."+props.state.file_ext}/>}
				</div>
				<div className="preview">
					<ul>
						{
							props.state.photos && props.state.photos.length > 1 && props.state.photos.map(
								(item) => {
									return (
											<li key={`photo${item.file_uuid}`}><a onClick={(event)=>{props.select_item_photo(item);event.preventDefault()}}><Lazy_image className="prev" placeholder="/images/photo-loader.gif" src={"/photo/good/s1/"+item.file_uuid+"."+item.file_ext} /></a></li>
									)
								}
							)
						}
					</ul>
				</div>
			</div>)
	}

	const Main = () => {
		return (<div className="main"><table><tbody>
		{
			props.state.main && props.state.main.map(
				(item,index) => {
					return (<tr key={`prop_${item.name}`}><td className="name" >{item.name}</td><td className="value">{item.value}</td></tr>)
				}
			)
		}
		</tbody></table></div>)
	}

	if(props.state.isFetching || props.metadata.isFetching)
		return (<CircularProgress/>)
	else if (props.state.isError)
		return <Error_data repeate={(event)=>props.select_item(props.apikey,props.state.uuid,props.state.catalog)} />
	else if(!props.state.isReceived && props.path !== undefined)
	{
		let locationInfo = location_info(props.path);
		if(locationInfo.catalog && locationInfo.item)
			select_item(locationInfo.item,locationInfo.variant,locationInfo.catalog);
	}

	return (
		<div className="itemblock">
			<Grid container spacing={3}>
				<Grid item xs={12} md={5}>
					<Photo />
				</Grid>
				<Grid item xs={12} md={7}>
					<Grid container spacing={0}>
						<Grid item xs={12} md={12}>
							<h1>{props.state.art}</h1>
						</Grid>
						<Grid item xs={12} md={12}>
							<Store item={props.state} store={props.store} guid={props.state.uuid} variant_uuid={props.state.variant_uuid} />
						</Grid>
						{/*<Grid item xs={12} md={12}>*/}
						{/*	<Main />*/}
						{/*</Grid>*/}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

const mapStateToProps = function(state) {
	return {
	  	'state':state.item,
	  	'apikey':state.auth.key,
	  	'metadata':state.metadata
	}
}

const mapDispatchToProps = function(dispatch,state) {
	return {
		'select_item': (key,item,variant,catalog) => {
			dispatch(select_item(key,item,variant,catalog));
		},
		'select_item_photo': (item) => {
			dispatch(select_item_photo(item));
		},
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ItemContainer);

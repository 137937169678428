import {
    REQUEST_ITEM,
    ERROR_ITEM,
    RECEIVE_ITEM,
    FLUSH_ITEM,
    SET_ITEM_PHOTO,
    FLUSH_CATALOG
} from '../types'

let item = {
    isFetching: false,
    isReceived: false,
    isError: false,
    uuid: undefined,
    catalog: undefined,
    good: '',
    photos: []
}

const itemReducer = (state = undefined, action) => {

    if (state === undefined)
        state = JSON.parse(JSON.stringify(item));

    let newState;

    switch (action.type) {
        case REQUEST_ITEM:
            newState = Object.assign({}, state, {
                isFetching: true,
                isError: false,
                uuid: action.item,
                variant_uuid: action.variant,
                catalog: action.catalog
            });
            return newState;
        case RECEIVE_ITEM:
            newState = Object.assign({}, state, {isReceived: true, isFetching: false, isError: false}, action.data);
            return newState;
        case ERROR_ITEM:
            newState = Object.assign({}, state, {isFetching: false, isError: true});
            return newState;
        case FLUSH_ITEM:
            newState = Object.assign({}, item);
            return newState;
        case FLUSH_CATALOG:
            newState = Object.assign({}, item);
            return newState;
        case SET_ITEM_PHOTO:
            newState = Object.assign({}, state, action.item);
            return newState;
        default:
            return state;
    }
}

export default itemReducer;
import React, {useEffect, useState} from 'react';
import {Avatar, ListItemIcon, Menu, MenuItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {auth} from "./redux/actions";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

function Userbar({exit,personal}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="userbar">
            <div className="userbar_user">
                <button className="image" type="button" onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                        <defs>
                            <mask x="0" y="0" width="60" height="60">
                                <g>
                                    <circle cx="30" cy="30" r="30" fill="#fff"></circle>
                                </g>
                            </mask>
                        </defs>
                        <g>
                            <g>
                                <circle cx="30" cy="30" r="30" fillOpacity="0"></circle>
                                <g>
                                    <path className="cls-4" fill="#000" fillOpacity=".8" d="M0 0h60v60H0z"></path>
                                </g>
                                <g>
                                    <path className="cls-5"
                                          d="M46.68 43.83c-2-5.8-6.9-9.69-16.63-9.69s-14.58 3.77-16.84 9.69"></path>
                                </g>
                                <g>
                                    <circle className="cls-5" cx="30" cy="20.86" r="8.29"></circle>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div className="tlk-userbar__popup">
                <div className="tlk-userbar__popup-wrap">
                    <div className="tlk-userbar__popup-content"></div>
                </div>
            </div>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                className="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    {personal?.partner?.partner}
                </MenuItem>
                {/*<Divider />*/}
                {/*<MenuItem onClick={handleClose}>*/}
                {/*    <NavLink to="/orders/">Заказы</NavLink>*/}
                {/*</MenuItem>*/}
                <Divider />
                <MenuItem onClick={exit}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Выйти
                </MenuItem>
            </Menu>

        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        state: state.auth,
        alert: state.alert,
        personal : state.personal
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        'exit': (key) => {
            dispatch({'type': 'AUTH_LOGOUT'});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Userbar);


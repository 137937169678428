Array.prototype.ffilter = function (func, res = undefined) {
    let result = this.filter(func);
    if (result.length > 0) {
        return result[0];
    } else {
        return res;
    }
};

Date.prototype.datetimestr = function (func, res = undefined) {
    var date = new Date(this);

    var date_str;

    date_str = [
        ('0' + date.getDate()).slice(-2),
        ('0' + (date.getMonth() + 1)).slice(-2),
        date.getFullYear(),
    ].join('.');

    date_str +=
        ' ' +
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2);

    return date_str;
};

Date.prototype.datestr = function (func, res = undefined) {
    var date = new Date(this);

    var date_str;

    date_str = [
        ('0' + date.getDate()).slice(-2),
        ('0' + (date.getMonth() + 1)).slice(-2),
        date.getFullYear(),
    ].join('.');

    return date_str;
};

Date.prototype.timestr = function (func, res = undefined) {
    var date = new Date(this);

    var date_str;

    date_str =
        ' ' +
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2);

    return date_str;
};

String.prototype.strim = function (count) {
    if(this.length > count)
        return `${this.substring(0,count)}...`
    else
        return this
};

Array.prototype.ffilter = function(func,res=undefined) {
    let result = this.filter(func);
    if(result.length>0)
        return result[0];
    else
        return res;
};

Date.prototype.calcday = function (count) {
    let newDate = new Date(this);
    newDate.setDate(newDate.getDate() + count);
    return newDate;
}

Date.prototype.s_datestr = function (func, res = undefined) {
    let date = new Date(this);

    let date_str;

    date_str = [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2),
    ].join('-');

    return date_str;
};


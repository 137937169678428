import React from "react";

import {auth} from './redux/actions.js'

import {connect} from 'react-redux';
import {useAlert} from "react-alert";
import Login from './login';
import Personal from './personal';


const HomeContainer = (props) => {

    const alert = useAlert();

    if (props.alert.errors.length > 0)
        props.alert.errors.forEach(function (item, i, arr) {
            alert.error(item);
            props.alerts_clean();
        });

    React.useEffect(() => {
        document.addEventListener('touchmove', function(event) {
            event = event.originalEvent || event;
            event.preventDefault();
            if(event.scale > 1) {
                event.preventDefault();
            }
        }, false);
    }, []);

    if (props.state.isLogin === false) {

        if (localStorage.authKey) {
            props.login(localStorage.authKey);
        }

        return (
            <div>
                <Login alert={alert}/>
            </div>
        );
    } else {
        return (
            <Personal exit={props.exit} alert={alert}
                      browserHistory={props.browserHistory}/>
        );
    }
};

const mapStateToProps = function (state) {
    return {
        'state': state.auth,
        'alert': state.alert
    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        'handleChange': (id, name, value) => {
            dispatch({'type': 'CLIENT_EDIT_PERSON', 'text': {'id': id, 'name': name, 'value': value}})
        },
        'login': (key) => {
            dispatch(auth(key));
        }
        ,
        'exit': (key) => {
            dispatch({'type': 'AUTH_LOGOUT'});
        },
        'alerts_clean': (key) => {
            dispatch({'type': 'ALERT_ERROR_CLEAN'});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

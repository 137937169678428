import Catalog_menu from "./catalog_menu_items";
import React from "react";
import {connect} from "react-redux";

function Category_list({state,browserHistory}) {
    return (
        <div className={"category_list"}>
            {
                state.catalogs.map((item, index) => {
                    return (
                        <Catalog_menu key={`menu_${item.catalog_uuid}`}
                                      uuid={item.catalog_uuid} browserHistory={browserHistory}/>
                    )
                })
            }
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        'state': state.metadata,
    }
}

export default connect(mapStateToProps)(Category_list);
